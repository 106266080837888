define("dashboard/templates/components/account/non-app-authorizations-list-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3HSVl/Go",
    "block": "[[[10,\"td\"],[14,0,\"application\"],[12],[1,\"\\n  \"],[1,[30,0,[\"authorization\",\"description\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"td\"],[14,0,\"permissions\"],[12],[1,\"\\n  \"],[1,[30,0,[\"displayScope\"]]],[1,\"\\n\"],[13],[1,\"\\n\\n\\n\"],[10,\"td\"],[14,0,\"tr\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"link underline pointer\"]],[[\"@route\",\"@model\"],[\"account.applications.edit-authorization\",[30,0,[\"authorization\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[[29,[\"Edit \",[30,0,[\"authorization\",\"description\"]],\" authorization\"]],\"edit-28\",\"malibu-fill-gradient-purple\",20]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/account/non-app-authorizations-list-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
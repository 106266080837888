define("dashboard/components/radio-cell-button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class RadioCellButtonComponent extends _component.default {
    get isSelected() {
      return this.args.selectedValue === this.args.value;
    }

  }

  _exports.default = RadioCellButtonComponent;
});
define("dashboard/components/enterprise-account/welcome-banner", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _component, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const STATE_DISMISSED = 'dismissed';
  const STATE_COLLAPSED = 'collapsed';
  const STATE_EXPANDED = null;

  var _default = _component.default.extend({
    tagName: '',
    // --------------------------------------------------------------------------
    // Services
    analytics: (0, _service.inject)(),
    media: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    enterpriseAccount: null,
    enterpriseAccountMember: null,
    enterpriseAccountPreferences: null,
    // --------------------------------------------------------------------------
    // Computed Properties
    isMobile: (0, _computed.alias)('media.isMobile'),
    bannerState: (0, _computed.alias)('enterpriseAccountPreferences.welcomeBannerState'),
    isStateDismissed: (0, _computed.equal)('bannerState', STATE_DISMISSED),
    isStateCollapsed: (0, _computed.equal)('bannerState', STATE_COLLAPSED),
    isCollapsed: (0, _computed.or)('isMobile', 'isStateCollapsed'),
    isDismissed: (0, _computed.readOnly)('isStateDismissed'),
    // eslint-disable-next-line complexity
    features: (0, _object.computed)('enterpriseAccountMember.{canManage,canCreate,canBill}', 'enterpriseAccount.trial', function () {
      const {
        canManage,
        canCreate,
        canBill
      } = this.enterpriseAccountMember;
      const isNotTrialAccount = !this.enterpriseAccount.trial;
      const createPermitted = canCreate && isNotTrialAccount;

      if (canManage && canBill && createPermitted) {
        return [Features.renameTeam, Features.manageAccess, Features.sso, Features.createTeam, Features.usage];
      } else if (canManage && createPermitted) {
        return [Features.renameTeam, Features.manageAccess, Features.sso, Features.auditLogs, Features.createTeam];
      } else if (createPermitted && canBill) {
        return [Features.teamsList, Features.membersList, Features.createTeam, Features.usage];
      } else if (canManage && canBill) {
        return [Features.renameTeam, Features.manageAccess, Features.sso, Features.usage, Features.auditLogs];
      } else if (createPermitted) {
        return [Features.teamsList, Features.membersList, Features.createTeam];
      } else if (canManage) {
        return [Features.renameTeam, Features.manageAccess, Features.sso, Features.auditLogs];
      } else if (canBill) {
        return [Features.teamsList, Features.membersList, Features.usage];
      } else {
        return [Features.teamsList, Features.membersList];
      }
    }),

    // --------------------------------------------------------------------------
    // Actions
    collapseBanner() {
      this.analytics.logEvent('Enterprise Account Welcome Banner', 'Collapsed');
      this.setState(STATE_COLLAPSED);
    },

    expandBanner() {
      this.analytics.logEvent('Enterprise Account Welcome Banner', 'Expanded');
      this.setState(STATE_EXPANDED);
    },

    dismissBanner() {
      this.analytics.logEvent('Enterprise Account Welcome Banner', 'Dismissed');
      this.setState(STATE_DISMISSED);
    },

    setState(state) {
      const preference = this.enterpriseAccountPreferences;
      preference.set('welcomeBannerState', state);
      return preference.save();
    }

  });

  _exports.default = _default;
  const Features = {
    teamsList: _object.default.create({
      icon: 'chart-28',
      title: 'New teams list',
      description: 'Now you have a much better way to view and find your teams!',
      linkTitle: 'View teams',
      analyticsNoun: 'Enterprise Account Welcome Banner View Teams Button'
    }),
    membersList: _object.default.create({
      icon: 'team-28',
      title: 'Account members list',
      description: 'Improved visibility of Enterprise Account members with filtering and pagination features.',
      linkTitle: 'View members',
      linkUrl: 'enterprise-account.overview.access',
      analyticsNoun: 'Enterprise Account Welcome Banner View Members Button'
    }),
    missingTeam: _object.default.create({
      icon: 'help-badge-28',
      title: 'Missing a team?',
      description: 'Ask an account member with the Create permission to transfer an existing team into the account.',
      linkTitle: 'Check permissions',
      linkUrl: 'enterprise-account.overview.access',
      analyticsNoun: 'Enterprise Account Welcome Banner Check Permissions Button'
    }),
    usage: _object.default.create({
      icon: 'usage-28',
      title: 'Download usage',
      description: 'Download detailed monthly, or daily usage information for a specific date range.',
      linkTitle: 'Download usage',
      linkUrl: 'enterprise-account.overview.usage',
      analyticsNoun: 'Enterprise Account Welcome Banner Download Usage Button'
    }),
    renameTeam: _object.default.create({
      icon: 'account-identicon',
      title: 'Did we get the name right?',
      description: 'We used the same name as your enterprise team but you can rename it anytime.',
      linkTitle: 'Rename account',
      linkUrl: 'enterprise-account.overview.settings',
      analyticsNoun: 'Enterprise Account Welcome Banner Rename Account Button'
    }),
    manageAccess: _object.default.create({
      icon: 'team-28',
      title: 'Manage account users',
      description: 'Add users to this account and manage their roles, so they can be added to teams and apps.',
      linkTitle: 'Manage access',
      linkUrl: 'enterprise-account.overview.access',
      analyticsNoun: 'Enterprise Account Welcome Banner Manage Access Button'
    }),
    sso: _object.default.create({
      icon: 'lock-locked-28',
      title: 'Set up Single Sign-on',
      description: 'Set up your Identity Provider to enable Single Sign-On for the members of this account.',
      linkTitle: 'Configure SSO',
      linkUrl: 'enterprise-account.overview.settings',
      analyticsNoun: 'Enterprise Account Welcome Banner Configure SSO Button'
    }),
    auditLogs: _object.default.create({
      icon: 'check-28',
      title: 'Audit logs',
      description: 'Download your audit logs to meet various auditing and compliance requirements.',
      linkTitle: 'Download audit logs',
      linkUrl: 'enterprise-account.overview.settings',
      analyticsNoun: 'Enterprise Account Welcome Banner Download Audit Logs Button'
    }),
    transferTeams: _object.default.create({
      icon: 'transfer-28',
      title: 'Transfer teams',
      description: 'Easily transfer your other Enterprise Teams to the new Enterprise Account.',
      linkTitle: 'Transfer teams',
      linkUrl: 'enterprise-account.overview.settings',
      analyticsNoun: 'Enterprise Account Welcome Banner Transfer Teams Button'
    }),
    createTeam: _object.default.create({
      icon: 'team-identicon',
      title: 'Create a new team',
      description: 'Create a new team for this account. Team users will be able to access and view the apps in it.',
      linkTitle: 'Create team',
      linkUrl: 'enterprise-account.overview.index',
      linkQuery: {
        create: 'team'
      },
      analyticsNoun: 'Enterprise Account Welcome Banner Create Team Button'
    })
  };
});
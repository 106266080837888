define("dashboard/routes/app/datastore", ["exports", "dashboard/routes/basic-app", "dashboard/mixins/document-title", "@ember/object/computed", "@ember/service", "rsvp"], function (_exports, _basicApp, _documentTitle, _computed, _service, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _basicApp.default.extend(_documentTitle.default, {
    analytics: (0, _service.inject)(),
    breadcrumbs: (0, _service.inject)(),
    current: (0, _service.inject)(),
    store: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    isDashboardDataUXEnabled: (0, _computed.readOnly)('accountFeatures.dashboardDataUx.enabled'),

    async model(params) {
      const addon = await this.store.find('addon', params.datastore_id);
      const app = await addon.get('app');
      const team = await app.get('team');
      let datastore;

      if (addon.isKafka) {
        datastore = await this.store.findRecord('shogun/kafka-datastore', params.datastore_id);
        await datastore.belongsTo('topicDetails').reload();
      } else if (addon.isPostgres && this.isDashboardDataUXEnabled) {
        datastore = await this.store.findRecord('shogun/postgres-datastore', params.datastore_id);
      } else if (addon.isRedis) {
        datastore = await this.store.findRecord('shogun/redis-datastore', params.datastore_id);
      } else {
        this.transitionTo('/404');
      }

      return _rsvp.default.hash({
        datastore,
        addon,
        team
      });
    },

    afterModel(model) {
      const {
        addon
      } = model;

      if (addon.isKafka || addon.isPostgres || addon.isRedis) {
        const type = model.addon.addonService.get('humanName').replace('Heroku ', '');
        this.analytics.logEvent(`${type} Datastore Page`, 'Viewed');
      }
    },

    renderTemplate(model) {
      this.set('breadcrumbs.trail', {
        datastore: model,
        addon: model.addon,
        team: model.team
      });
      this.render('app/datastore/header', {
        outlet: 'header-nav',
        into: 'protected'
      });
      this.render('app/datastore', {
        into: 'protected'
      });
    }

  });

  _exports.default = _default;
});
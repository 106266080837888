define("dashboard/components/app/datastore/view-credential-slide-panel", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "URICopySuccessful", _descriptor, this);

      _initializerDefineProperty(this, "selectedApp", _descriptor2, this);
    }

    get credentialDetails() {
      return this.args.credential.credentials[0];
    }

    get credentialAttachments() {
      const addonAttachments = this.args.addonAttachments;
      const credentialName = this.args.credential.name;

      if (this.args.credential.name === 'default') {
        const defaultAttachments = addonAttachments.filterBy('namespace', null);
        const credentialAttachments = addonAttachments.filterBy('namespace', `credential:${credentialName}`);
        return [...defaultAttachments, ...credentialAttachments];
      } else {
        return addonAttachments.filterBy('namespace', `credential:${credentialName}`);
      }
    }

    get URI() {
      return `postgres://${this.credentialDetails.user}:${this.credentialDetails.password}@${this.args.credential.host}:${this.args.credential.port}/${this.args.credential.database}`;
    }

    get appsAttached() {
      return this.credentialAttachments.mapBy('app.id');
    }

    get appsNotAttached() {
      const allApps = this.args.apps;
      return allApps.reject(app => {
        return this.appsAttached.includes(app.get('id'));
      });
    }

    get disableAddAttachmentButton() {
      return !this.selectedApp;
    }

    async copyURI() {
      try {
        await navigator.clipboard.writeText(this.URI);
        this.URICopySuccessful = true;
      } catch (e) {
        return;
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "URICopySuccessful", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "selectedApp", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "copyURI", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "copyURI"), _class2.prototype)), _class2);

  _exports.default = _class;
});
define("dashboard/templates/components/pipeline/app-deployment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "/LAX3UO8",
    "block": "[[[41,[30,0,[\"deploymentIsLoaded\"]],[[[1,\"  \"],[10,0],[14,0,\"deploy-activity flex items-center gray mt1 relative\"],[12],[1,\"\\n\"],[41,[30,0,[\"isRollback\"]],[[[1,\"      Rolled back to \"],[10,1],[14,0,\"badge\"],[12],[1,[30,0,[\"rollbackVersion\"]]],[13],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"datetime\"],[[30,0,[\"releasedAt\"]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"deployment\",\"commit\"]],[[[1,\"        \"],[10,0],[14,0,\"commit-sha flex items-center bg-light-silver br1 dib pv0 mr1\"],[12],[1,\"\\n          \"],[10,1],[14,0,\"commit-sha-ref-twopoint monospace f7 truncate\"],[12],[1,\"\\n            \"],[8,[39,2],[[24,0,\"dark-gray underline-hover ph--4\"]],[[\"@route\",\"@model\"],[\"app.activity\",[30,0,[\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[30,0,[\"deployment\",\"commit\"]]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n          \"],[1,[28,[35,3],null,[[\"commitStatuses\",\"ref\",\"class\"],[[30,0,[\"commitStatuses\"]],[30,0,[\"deployment\",\"commit\"]],\"tester\"]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showAvatar\"]],[[[1,\"        \"],[1,[28,[35,4],null,[[\"tooltip\",\"email\",\"class\",\"size\"],[true,[30,0,[\"deployment\",\"user\",\"email\"]],\"mr1\",16]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,1],[14,0,\"f6 flex-auto truncate nudge-up--1\"],[12],[1,\"Deployed \"],[1,[28,[35,1],null,[[\"datetime\",\"capitalize\"],[[30,0,[\"releasedAt\"]],false]]]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"time-ago\",\"link-to\",\"github-commit-status\",\"gravatar-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/app-deployment.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/templates/components/enterprise-account/welcome-banner-feature", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "H/+TorzJ",
    "block": "[[[10,0],[14,0,\"flex flex-column items-center w6 mh2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"mb1\"],[12],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"icon\"]],\"team-identicon\"],null],[[[1,\"      \"],[10,0],[14,0,\"x-identicon inline-flex flex-shrink-0 items-center justify-center w2 h2 ba br-100 b--gradient-dark-gray\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"dark-gray f5 fw6\"],[12],[1,\"Dx\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[28,[37,1],[[30,0,[\"icon\"]],\"account-identicon\"],null],[[[1,\"      \"],[10,0],[14,0,\"x-identicon x-identicon-account inline-flex flex-shrink-0 items-center justify-center w2 h2 br1 bg-gradient-dark-gray\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"white f5 fw6\"],[12],[1,\"Hk\"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[[30,0,[\"icon\"]],\"malibu-fill-gradient-dark-gray\",24]],null],[1,\"\\n    \"]],[]]]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mb1 b\"],[12],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"mb3 flex-auto f5\"],[12],[1,\"\\n    \"],[1,[30,0,[\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"linkUrl\"]],[[[1,\"      \"],[8,[39,3],[[24,0,\"hk-button--secondary\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0],[30,0,[\"analytics\",\"logEvent\"]],[30,0,[\"analyticsNoun\"]],\"Clicked\"],null]],null]],[[\"@route\",\"@query\"],[[30,0,[\"linkUrl\"]],[30,0,[\"linkQuery\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,0,[\"linkTitle\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,6],null,[[\"disabled\",\"default\"],[true,[30,0,[\"linkTitle\"]]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"if\",\"eq\",\"malibu-icon\",\"link-to\",\"on\",\"action\",\"hk-button\"]]",
    "moduleName": "dashboard/templates/components/enterprise-account/welcome-banner-feature.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/models/addon", ["exports", "@ember/object/computed", "@ember/object", "@ember-data/model", "@ember/service"], function (_exports, _computed, _object, _model, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    actions: (0, _model.attr)(),
    configVars: (0, _model.attr)(),
    name: (0, _model.attr)('string'),
    embeddedAddonServicePlan: (0, _model.attr)(),
    state: (0, _model.attr)('string'),
    webUrl: (0, _model.attr)('string'),
    plan: (0, _model.attr)('string'),
    createdAt: (0, _model.attr)('date'),
    addonService: (0, _model.belongsTo)('addon-service'),
    addon_service: (0, _computed.alias)('addonService'),
    app: (0, _model.belongsTo)('app'),
    appName: (0, _computed.alias)('app.name'),
    attachments: (0, _model.hasMany)('attachment'),
    addonServicePlan: (0, _model.belongsTo)('addon-service-plan'),
    billingEntity: (0, _model.attr)(),
    billingEntityId: (0, _computed.alias)('billingEntity.id'),
    billingEntityName: (0, _object.computed)('billingEntity.name', 'appName', function () {
      return this.get('billingEntity.name') || this.appName;
    }),
    billedPrice: (0, _model.attr)(),
    billedPriceCents: (0, _computed.alias)('billedPrice.cents'),
    priceInCents: (0, _computed.alias)('billedPriceCents'),
    priceIsContract: (0, _computed.alias)('billedPrice.contract'),
    shouldDisplayHourlyPrice: (0, _object.computed)('priceInCents', 'priceIsContract', function () {
      return !(this.priceIsContract || this.priceInCents === 0);
    }),
    accountFeatures: (0, _service.inject)(),
    addonServicePlans: (0, _computed.alias)('addonService.addonServicePlans'),
    sortedAddonServicePlans: (0, _computed.alias)('addonService.sortedAddonServicePlans'),
    allAssociatedAppsWithDupes: (0, _computed.mapBy)('attachments', 'app'),
    allAssociatedApps: (0, _computed.uniqBy)('allAssociatedAppsWithDupes', 'id'),
    allSortedAssociatedApps: (0, _object.computed)('allAssociatedApps', function () {
      if (this.allAssociatedApps) {
        return this.allAssociatedApps.sortBy('name');
      } else {
        return null;
      }
    }),
    hid: (0, _computed.alias)('attachments.firstObject.embeddedAddon.hid'),
    //
    // Addon Service
    //
    providerId: (0, _computed.alias)('addon_service.id'),
    providerName: (0, _computed.alias)('addon_service.name'),
    providerDescription: (0, _computed.alias)('addon_service.human_name'),
    isPostgres: (0, _computed.alias)('addon_service.isPostgres'),
    isKafka: (0, _computed.alias)('addon_service.isKafka'),
    isRedis: (0, _computed.alias)('addon_service.isRedis'),
    isHobby: (0, _object.computed)('plan', function () {
      return !!this.plan.match(/basic|dev|hobby|mini/);
    }),
    isHerokuScheduler: (0, _computed.alias)('addon_service.isHerokuScheduler'),
    // isPrivateSpaceApp only works if the instance is instantiated with a full app object passed in.
    //   - otherwise app.space is not present
    isPrivateSpaceApp: (0, _computed.alias)('app.isPrivateSpaceApp'),
    isFreeHerokuData: (0, _object.computed)('isPostgres', 'isRedis', 'priceInCents', function () {
      const isFree = parseInt(this.priceInCents) === 0;
      return (this.isPostgres || this.isRedis) && isFree;
    }),
    //
    // Addon Service Plan
    //
    planName: (0, _computed.alias)('fullNameParts.1'),
    planDescription: (0, _computed.alias)('addonServicePlan.human_name'),
    planIsVisible: (0, _computed.alias)('addonServicePlan.isVisible'),
    planIsNotVisible: (0, _computed.alias)('addonServicePlan.isNotVisible'),
    hasMultipleServicePlans: (0, _computed.gt)('addonServicePlans.length', 1),
    planIsEditable: (0, _object.computed)('isPostgres', 'isHobby', 'hasMultipleServicePlans', 'planIsNotVisible', function () {
      if (this.isPostgres) {
        return this.isHobby;
      } else {
        return this.hasMultipleServicePlans || this.planIsNotVisible;
      }
    }),
    currentValidPlanOrDefaultPlan: (0, _object.computed)('addonServicePlan', 'defaultServicePlan', function () {
      if (this.get('addonServicePlan.isVisible')) {
        return this.addonServicePlan;
      } else {
        return this.defaultServicePlan;
      }
    }),
    defaultServicePlan: (0, _object.computed)('addonServicePlans', 'isPrivateSpaceApp', function () {
      if (this.isPrivateSpaceApp) {
        return this.get('addonService.defaultPrivateSpaceServicePlan');
      } else {
        return this.get('addonService.defaultServicePlan');
      }
    }),
    availableServicePlans: (0, _computed.filterBy)('sortedAddonServicePlans', 'visible', true),
    availableServicePlansForUpdate: (0, _object.computed)('addonServicePlan', 'availableServicePlans.[]', 'isPostgres', function () {
      let plans = this.availableServicePlans;

      if (this.isPostgres) {
        const allowedPostgresPlans = [this.get('addonServicePlan.name'), 'heroku-postgresql:mini', 'heroku-postgresql:basic'];
        plans = plans.filter(plan => allowedPostgresPlans.includes(plan.name));
      }

      return plans;
    }),

    fetchAttachments(store) {
      store.query('attachment', {
        addonId: this.id
      }).then(addonAttachments => {
        const {
          id
        } = this;
        const data = addonAttachments.toArray().map(a => {
          return {
            id: a.id,
            type: 'attachment'
          };
        });
        store.push({
          data: {
            id,
            type: 'addon',
            relationships: {
              attachments: {
                data
              }
            }
          }
        });
      });
    },

    hasServicePlans: (0, _computed.notEmpty)('addonServicePlans'),
    fullNameParts: (0, _object.computed)('plan', function () {
      return this.plan ? this.plan.split(':') : null;
    }),

    setAddonServicePlanByName(planName) {
      const matchingPlan = this.get('addonService.addonServicePlans').findBy('name', planName);

      if (!matchingPlan || !matchingPlan.get('isVisible')) {
        this.set('addonServicePlan', this.defaultServicePlan);
      } else {
        this.set('addonServicePlan', matchingPlan);
      }

      this.set('plan', matchingPlan.get('name'));
    },

    //
    // Private Spaces & Non-Peerable Addons
    //
    // this field is needed to POST if it is a non-peerable addon within a private space
    confirm: (0, _object.computed)('confirmNotAutoNetworked', function () {
      if (this.confirmNotAutoNetworked) {
        return this.get('app.name');
      } else {
        return '';
      }
    }),
    // it is valid except in the case where it is a non-peerable addon within a private space and the user has not confirmed
    isNotNonPeerableAndNotConfigured: (0, _object.computed)('confirmNotAutoNetworked', 'nonPeerableWithinPrivateSpace', function () {
      if (this.nonPeerableWithinPrivateSpace && !this.confirmNotAutoNetworked) {
        return false;
      } else {
        return true;
      }
    }),
    isNotValid: (0, _computed.not)('isNotNonPeerableAndNotConfigured'),
    nonPeerableWithinPrivateSpace: (0, _object.computed)('isPrivateSpaceApp', 'addonServicePlan', function () {
      const isPrivateSpaceApp = this.isPrivateSpaceApp;

      if (isPrivateSpaceApp) {
        const planName = this.get('addonServicePlan.name');
        return !this.planIsPeerableWithinPrivateSpace(planName);
      } else {
        return false;
      }
    }),

    planIsPeerableWithinPrivateSpace(planName) {
      const addonServicePlan = this.get('addonService.addonServicePlans').findBy('name', planName);
      return addonServicePlan.get('installableInsidePrivateNetwork');
    }

  });

  _exports.default = _default;
});
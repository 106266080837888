define("dashboard/templates/components/github-integration-reenabled-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "q25zDO0f",
    "block": "[[[41,[30,0,[\"showBanner\"]],[[[1,\"  \"],[10,0],[14,0,\"message-banner hk-banner--info clearfix\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"limit-width center\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"info\",\"info-badge-16\",\"malibu-fill-gradient-blue\",16]],null],[1,\"\\n      \"],[10,2],[14,0,\"message-banner-text\"],[12],[1,\"\\n        \"],[10,\"strong\"],[12],[1,\"GitHub integration is available again\"],[13],[1,\"\\n        \"],[10,\"small\"],[12],[1,\"\\n          During remediation of a recent \"],[10,3],[14,6,\"https://status.heroku.com/incidents/2413\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link\"],[12],[1,\"security incident\"],[13],[1,\", your account has lost access to your connected GitHub account.\"],[10,\"br\"],[12],[13],[1,\"\\n          You can \"],[10,3],[14,6,\"https://help.heroku.com/UIUA61EH/how-do-i-reconnect-the-github-integration\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link\"],[12],[1,\"reconnect immediately\"],[13],[1,\" or wait for the \"],[10,3],[14,6,\"https://blog.heroku.com/github-integration-update\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"hk-link\"],[12],[1,\"enhanced integration with GitHub\"],[13],[1,\".\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"flex message-banner-actions\"],[12],[1,\"\\n        \"],[8,[39,2],[[24,1,\"primary-button\"],[24,0,\"hk-button-sm--info ml2\"]],[[\"@route\"],[\"pipelines.pipeline.settings\"]],[[\"default\"],[[[[1,\"Reconnect to GitHub\"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-icon\",\"link-to\"]]",
    "moduleName": "dashboard/templates/components/github-integration-reenabled-banner.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
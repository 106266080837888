define("dashboard/templates/components/app/datastore/new-credential-slide-panel", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "GNZslpFq",
    "block": "[[[8,[39,0],null,[[\"@close\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,2,[\"header\"]],null,[[\"@content\"],[\"New Postgres Credential\"]],null],[1,\"\\n\\n  \"],[8,[30,2,[\"content\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"flex flex-column h-100\"],[12],[1,\"\\n      \"],[10,\"form\"],[14,\"role\",\"form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"pv3\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"flex items-center mb1\"],[12],[1,\"\\n            \"],[10,\"label\"],[14,0,\"f5 b dark-gray dib pr2 border-box\"],[12],[1,\"\\n              \"],[10,1],[14,0,\"mr1\"],[12],[1,\"\\n                Credential Name\\n              \"],[13],[1,\"\\n              \"],[10,1],[14,0,\"hk-badge--square ml1\"],[12],[1,\"Required\"],[13],[1,\"\\n            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n          \"],[10,0],[14,0,\"mb1\"],[12],[1,\"\\n            \"],[8,[39,1],null,[[\"@inputClass\",\"@type\",\"@value\",\"@model\",\"@validatingKey\",\"@errorIsVisible\"],[\"hk-input w-100\",\"text\",[30,0,[\"newCredential\",\"name\"]],[30,0,[\"newCredential\"]],\"name\",[30,0,[\"newCredential\",\"errorIsVisible\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[30,2,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,2,[\"closeButton\"]],null,null,[[\"default\"],[[[[1,\"\\n      Cancel\\n    \"]],[]]]]],[1,\"\\n\\n    \"],[8,[30,2,[\"primaryButton\"]],null,[[\"@onClick\",\"@disableWhen\"],[[28,[37,2],[[30,3],[30,0,[\"newCredential\",\"name\"]],[30,2,[\"dismiss\"]]],null],[30,0,[\"newCredential\",\"validations\",\"isInvalid\"]]]],[[\"default\"],[[[[1,\"\\n      Create\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"  \\n\"]],[2]]]]],[1,\"\\n\"]],[\"@onDismissSlidePanel\",\"sp\",\"@createCredential\"],false,[\"hk-slide-panel\",\"cp-validating-input\",\"fn\"]]",
    "moduleName": "dashboard/templates/components/app/datastore/new-credential-slide-panel.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
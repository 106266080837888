define("dashboard/templates/components/radio-cell-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qfHGKUFT",
    "block": "[[[11,\"label\"],[16,0,[29,[\"br2 ba b--light-silver mb2 cursor-pointer overflow-hidden w-100 shadow-outer-3 \",[52,[30,0,[\"isSelected\"]],\"b--blue\",\"hover-b--lightest-blue\"]]]],[16,\"for\",[30,1]],[17,2],[4,[38,1],[\"click\",[30,3]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"pa3 dark-gray\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex items-center ma0 cursor-pointer\"],[12],[1,\"\\n      \"],[10,\"input\"],[15,2,[30,4]],[15,\"checked\",[30,0,[\"isSelected\"]]],[15,\"aria-checked\",[52,[30,0,[\"isSelected\"]],\"true\",\"false\"]],[14,0,\"nudge-up--2 cursor-pointer\"],[15,1,[30,1]],[14,4,\"radio\"],[12],[13],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[18,6,null],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"bg-lightest-silver pv2 ph3 gray\"],[12],[1,\"\\n    \"],[18,7,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@radioId\",\"&attrs\",\"@onClickAction\",\"@value\",\"&title\",\"&top\",\"&bottom\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "dashboard/templates/components/radio-cell-button.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
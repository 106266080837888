define("dashboard/templates/components/pipeline/kolkrabbi-app-setup-status", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "wc4rV8fE",
    "block": "[[[41,[30,0,[\"githubReviewApp\",\"isPending\"]],[[[1,\"    \"],[1,[28,[35,1],null,[[\"size\",\"class\"],[\"small\",\"ml--4 mr1 nudge-up--1\"]]]],[1,\"\\n    \"],[10,1],[14,0,\"flex-auto f6 gray ml1 pl--1\"],[12],[1,\"\\n      Creating app •\\n      \"],[8,[39,2],[[24,0,\"hk-link no-underline underline-hover\"]],[[\"@route\",\"@models\"],[\"pipelines.pipeline.index.review-app\",[28,[37,3],[[30,0,[\"pipeline\",\"id\"]],[30,0,[\"githubReviewApp\",\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n        View log\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"githubReviewApp\",\"isFailed\"]],[[[1,\"  \"],[10,0],[14,0,\"red pa2 ma2 mt0 f6 bg-lightest-red br1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"flex\"],[12],[1,\"\\n      \"],[8,[39,4],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"Failed\",\"failure-badge-16\",\"flex-shrink-0 fill-red mr1\",16]],null],[1,\"\\n      \"],[10,1],[14,0,\"flex-auto f6 red nudge-up--1\"],[12],[1,\"\\n        \"],[1,[30,0,[\"githubReviewApp\",\"appSetup\",\"failure_message\"]]],[1,\"\\n\"],[41,[30,0,[\"githubReviewApp\",\"hasAppSetup\"]],[[[1,\"          •\\n          \"],[8,[39,2],[[24,0,\"hk-link red\"]],[[\"@route\",\"@models\"],[\"pipelines.pipeline.index.review-app\",[28,[37,3],[[30,0,[\"pipeline\",\"id\"]],[30,0,[\"githubReviewApp\",\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n            View log\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"malibu-loading-spinner\",\"link-to\",\"array\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/pipeline/kolkrabbi-app-setup-status.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
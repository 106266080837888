define("dashboard/models/dyno-tier", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Represents a tier containing dyno sizes. This is a hard-coded local model
   * and not backed by the API.
   *
   * You can see the full list of API-supported dyno types here:
   * https://github.com/heroku/api/blob/master/config/dyno_sizes.yml
   */
  const {
    match,
    not
  } = _object.computed;

  var _default = _object.default.extend({
    isProduction: match('name', /^production/i),
    isPrivate: match('name', /^private/i),
    isShield: match('name', /^shield/i),
    isFree: match('name', /^free$/i),
    isEco: match('name', /^eco$/i),
    isHobby: match('name', /^hobby$/i),
    isBasic: match('name', /^basic$/i),
    isNotFree: not('isFree'),
    lowerName: (0, _object.computed)('name', function () {
      return this.name.toLowerCase();
    }),

    findSize(size) {
      const sizeUpperCase = size.toUpperCase();
      return this.sizes.find(dynoSize => dynoSize.size.toUpperCase() === sizeUpperCase || dynoSize.alias?.toUpperCase() === sizeUpperCase);
    },

    sizes: []
  }).reopenClass({
    all() {
      return [this.freeTier(), this.ecoTier(), this.hobbyTier(), this.basicTier(), this.productionTier(), this.privateTier(), this.privateShieldTier()];
    },

    find(tierName) {
      return this.all().findBy('name', tierName);
    },

    findTierBySize(size) {
      const sizeUpperCase = size.toUpperCase();
      return this.all().find(tier => {
        return tier.sizes.find(dynoSize => dynoSize.size.toUpperCase() === sizeUpperCase);
      }) || this.freeTier();
    },

    freeTier() {
      return this.create({
        name: 'free',
        displayName: 'Free',
        defaultSize: 'Free',
        cells: [{
          name: 'Free',
          imgName: 'Free',
          description: 'Ideal for experimenting with cloud applications in a limited sandbox.',
          bullets: ['Sleeps after 30 mins of inactivity', 'Must sleep for 6 hours <br/>in a 24 hour period', 'Custom domains']
        }],
        sizes: [{
          size: 'Free',
          name: 'Free dynos',
          scale: 1,
          price: 0,
          hourMultiplier: 0,
          canAutoscale: false,
          dynomiteIconName: 'dynomite-free-16'
        }]
      });
    },

    ecoTier() {
      return this.create({
        name: 'eco',
        displayName: 'Eco',
        defaultSize: 'Eco',
        tooltipTitle: "Dyno hours are shared across all your Eco dynos. Eco dynos sleep after a period of inactivity and don't consume dyno hours while sleeping.",
        cells: [{
          name: 'Eco',
          imgName: 'Eco',
          description: 'Ideal for experimenting. Get 1000 dyno hours shared across all your Eco dynos.',
          bullets: ['Sleeps after 30 mins of inactivity', 'Must sleep for 6 hours <br/>in a 24 hour period', 'Custom domains']
        }],
        sizes: [{
          size: 'Eco',
          name: 'Eco dynos',
          scale: 1,
          price: 0,
          hourMultiplier: 0,
          canAutoscale: false,
          dynomiteIconName: 'dynomite-eco-16'
        }]
      });
    },

    hobbyTier() {
      return this.create({
        name: 'hobby',
        displayName: 'Hobby',
        defaultSize: 'Hobby',
        cells: [{
          name: 'Hobby',
          imgName: 'Hobby',
          description: 'Perfect for small scale personal projects and hobby apps.',
          bullets: ['All free features +', 'Never sleeps', 'Multiple worker process types']
        }],
        sizes: [{
          size: 'Hobby',
          name: 'Hobby dynos',
          scale: 1,
          price: 7,
          hourMultiplier: 1,
          canAutoscale: false,
          dynomiteIconName: 'dynomite-hobby-16'
        }]
      });
    },

    basicTier() {
      return this.create({
        name: 'basic',
        displayName: 'Basic',
        defaultSize: 'Basic',
        cells: [{
          name: 'Basic',
          imgName: 'Basic',
          description: "Perfect for small-scale personal projects and apps that don't need scaling.",
          bullets: ['All free features +', 'Never sleeps', 'Multiple worker process types']
        }],
        sizes: [{
          size: 'Basic',
          name: 'Basic dynos',
          scale: 1,
          price: 7,
          hourMultiplier: 1,
          canAutoscale: false,
          dynomiteIconName: 'dynomite-basic-16'
        }]
      });
    },

    productionTier() {
      return this.create({
        name: 'production',
        displayName: 'Professional',
        defaultSize: 'Standard-1X',
        features: 'Simple horizontal scalability',
        processes: 'Faster builds',
        cells: [{
          name: 'Standard 1X/2X',
          imgName: 'Production',
          description: 'Enhanced performance & visibility for powering professional apps.',
          bullets: ['All hobby features +', 'Simple horizontal scalability', 'Application metrics', 'Faster builds', 'Preboot', '512MB or 1GB Ram']
        }, {
          name: 'Performance-M/L',
          imgName: 'Performance',
          description: 'Superior performance for your very large-scale, high traffic apps.',
          bullets: ['All standard features +', 'Mix with standard 1x, 2x dynos', 'Dedicated', '', '', '2.5GB or 14GB RAM']
        }],
        sizes: [{
          size: 'Standard-1X',
          name: 'Standard-1X dynos',
          features: 'Shared',
          memory: '512 MB RAM',
          scale: 100,
          price: 25,
          hourMultiplier: 1,
          canAutoscale: false,
          dynomiteIconName: 'dynomite-1x-16'
        }, {
          size: 'Standard-2X',
          name: 'Standard-2X dynos',
          features: 'Shared',
          memory: '1 GB RAM',
          scale: 100,
          price: 50,
          hourMultiplier: 2,
          canAutoscale: false,
          dynomiteIconName: 'dynomite-2x-16'
        }, {
          size: 'Performance-M',
          alias: 'Intermediate',
          name: 'Performance-M dynos',
          features: 'Dedicated',
          memory: '2.5 GB RAM',
          scale: 10,
          price: 250,
          hourMultiplier: 8,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-pm-16'
        }, {
          size: 'Performance-L',
          alias: 'Performance',
          name: 'Performance-L dynos',
          features: 'Dedicated',
          memory: '14 GB RAM',
          scale: 10,
          price: 500,
          hourMultiplier: 16,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-pl-16'
        }]
      });
    },

    privateTier() {
      return this.create({
        name: 'private',
        displayName: 'Private',
        size: 'Private',
        features: 'Enhanced performance & security for powering enterprise apps.',
        processes: 'Faster builds',
        sizes: [{
          size: 'Private-S',
          optionLabel: 'Private-S',
          name: 'Private-S dynos',
          features: 'Dedicated',
          memory: '1024 MB RAM',
          scale: 100,
          price: 125,
          hourMultiplier: 5,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-space-ps-16'
        }, {
          size: 'Private-M',
          optionLabel: 'Private-M',
          name: 'Private-M dynos',
          features: 'Dedicated',
          memory: '2.5 GB RAM',
          scale: 100,
          price: 250,
          hourMultiplier: 10,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-space-pm-16'
        }, {
          size: 'Private-L',
          optionLabel: 'Private-L',
          name: 'Private-L dynos',
          features: 'Dedicated',
          memory: '14 GB RAM',
          scale: 100,
          price: 500,
          hourMultiplier: 20,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-space-pl-16'
        }]
      });
    },

    privateShieldTier() {
      return this.create({
        name: 'shield',
        displayName: 'Shield',
        size: 'Shield',
        features: 'Enhanced performance & security for powering enterprise apps.',
        processes: 'Faster builds',
        sizes: [{
          size: 'Shield-S',
          name: 'Shield-S dynos',
          features: 'Dedicated',
          memory: '1024 MB RAM',
          scale: 100,
          price: 270,
          hourMultiplier: 6,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-shield-ps-16'
        }, {
          size: 'Shield-M',
          name: 'Shield-M dynos',
          features: 'Dedicated',
          memory: '2.5 GB RAM',
          scale: 100,
          price: 540,
          hourMultiplier: 12,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-shield-pm-16'
        }, {
          size: 'Shield-L',
          name: 'Shield-L dynos',
          features: 'Dedicated',
          memory: '14 GB RAM',
          scale: 100,
          price: 1080,
          hourMultiplier: 24,
          canAutoscale: true,
          dynomiteIconName: 'dynomite-shield-pl-16'
        }]
      });
    }

  });

  _exports.default = _default;
});
define("dashboard/utils/metrics/receiving-language-data", ["exports", "@ember/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = receivingLanguageData;

  function receivingLanguageData(language) {
    return (0, _object.computed)('languageMemoryTest', `appPreferences.metricsSetUp${language}`, `languageMemoryTest.hasAnyMeasuredData${language}`, function () {
      let languageIsSetUp = this.get(`appPreferences.metricsSetUp${language}`);

      if (this.get('languageMemoryTest')) {
        const appPreferences = this.appPreferences;
        const languageDataIsDetected = this.get(`languageMemoryTest.hasAnyMeasuredData${language}`);

        if (appPreferences && languageDataIsDetected !== languageIsSetUp) {
          appPreferences.set(`metricsSetUp${language}`, languageDataIsDetected);
          appPreferences.save();
          languageIsSetUp = languageDataIsDetected;
        }
      }

      return languageIsSetUp;
    });
  }
});
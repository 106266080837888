define("dashboard/services/phoenix-adapter", ["exports", "ember", "@ember/service", "phoenix", "dashboard/config/environment"], function (_exports, _ember, _service, _phoenix, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    isTesting
  } = _ember.default;
  const wss = `${_environment.default.particleboardSocketUrl}/socket`; // This service implements the abstracted
  // websocket API and insulates the application
  // from the specific concrete socket API (Socket.io, Phoenix etc)

  var _default = _service.default.extend({
    session: (0, _service.inject)(),

    isConnected() {
      return !!(this._socket && this._socket.isConnected());
    },

    _newPhoenixSocket(url, options) {
      // Using Sinon to stub constructors is messy and
      // brittle. This indirection allows us to test
      // the parameters passed to Phoenix
      return new _phoenix.Socket(url, options);
    },

    connect() {
      let opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      if (!this._socket) {
        this._socket = this._newPhoenixSocket(wss, {
          heartbeatIntervalMs: 10000,
          ...opts
        });
      }

      if (!this.isConnected()) {
        if (isTesting) {
          throw new Error('Opening actual socket connection in test. Use `setupMockWebSocket` to prevent this error.');
        }

        this._socket.connect();
      }
    },

    disconnect() {
      if (this._socket) {
        this._socket.disconnect();
      }
    },

    onOpen(callback) {
      this._socket.onOpen(callback);
    },

    // onClose() {}
    // onError() {}
    join(room) {
      let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (!room) {
        return;
      }

      if (!this.isConnected()) {
        this.connect();
      }

      const channel = this._socket.channel(`events:${room}`, params);

      return {
        channel,
        connection: channel.join(30000)
      };
    }

  });

  _exports.default = _default;
});
define("dashboard/templates/app/datastore/header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "xB5lQ1Lo",
    "block": "[[[10,0],[14,0,\"flex flex-wrap items-center\"],[12],[1,\"\\n  \"],[1,[34,0]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"flex flex-wrap nh3-ns items-center\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"w-100 w-auto-ns ph3-ns mt3\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ttu f6 tracked pr2\"],[12],[1,\"\\n      Service\"],[13],[1,\"\\n    \"],[10,1],[14,0,\"dark-gray\"],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"addon\",\"addon_service\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"w-100 w-auto-ns ph3-ns mt3\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ttu f6 tracked pr2\"],[12],[1,\"\\n      Plan\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"dark-gray\"],[12],[1,\"\\n      \"],[1,[30,0,[\"model\",\"addon\",\"planName\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"w-100 w-auto-ns ph3-ns mt3\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"ttu f6 tracked pr2\"],[12],[1,\"\\n      Billing App\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"purple\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"link normal\"]],[[\"@route\",\"@model\"],[\"app\",[30,0,[\"model\",\"addon\",\"appName\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"app-16\",\"malibu-fill-gradient-purple\",16]],null],[1,\"\\n        \"],[1,[30,0,[\"model\",\"addon\",\"app\",\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"crumb-trail\",\"link-to\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/app/datastore/header.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/models/addon-service-plan", ["exports", "@ember/object/computed", "@ember-data/model", "@ember/object"], function (_exports, _computed, _model, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    addonService: (0, _model.belongsTo)('addon-service'),
    addon_service: (0, _computed.alias)('addonService'),
    name: (0, _model.attr)('string'),
    default: (0, _model.attr)('boolean'),
    description: (0, _model.attr)('string'),
    humanName: (0, _model.attr)('string'),
    human_name: (0, _computed.alias)('humanName'),
    installableInsidePrivateNetwork: (0, _model.attr)('boolean'),
    installableOutsidePrivateNetwork: (0, _model.attr)('boolean'),
    spaceDefault: (0, _model.attr)('boolean'),
    price: (0, _model.attr)(),
    state: (0, _model.attr)('string'),
    visible: (0, _model.attr)('boolean'),
    isVisible: (0, _computed.alias)('visible'),
    isNotVisible: (0, _computed.not)('isVisible'),
    priceInCents: (0, _computed.alias)('price.cents'),
    priceIsContract: (0, _computed.alias)('price.contract'),
    shouldDisplayHourlyPrice: (0, _object.computed)('priceInCents', 'priceIsContract', function () {
      return !(this.priceIsContract || this.priceInCents === 0);
    })
  });

  _exports.default = _default;
});
define("dashboard/components/app/addons-sso-link", ["exports", "@ember/component", "@ember/utils", "@ember/object", "@ember/service"], function (_exports, _component, _utils, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    router: (0, _service.inject)(),
    session: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    addon: null,
    app: null,
    // --------------------------------------------------------------------------
    // Computed Props
    // Checks for presence of attachments and their respective webUrls
    // If there are attachments, we find the web url that is mapped for the current
    // app and use that webUrl, otherwise just use the addon webUrl
    webUrlForApp: (0, _object.computed)('app.id', 'addon.webUrl', 'addon.attachments', 'addon.isHerokuScheduler', 'session.isAssumed', function () {
      const app = this.app;
      const addon = this.addon;
      const webUrl = this.addon.webUrl;
      const attachments = this.addon.attachments;
      const session = this.session; // Scheduler is an internal addon managed within dashboard
      // and due to an sso url redirect issue _while assuming an identity_
      // we need to redirect directly to the scheduler route

      if (session.isAssumed && addon.isHerokuScheduler) {
        const schedulerAddonUrl = this.router.urlFor('app.scheduler', app);
        return schedulerAddonUrl;
      }

      if (addon.isKafka) {
        const dataAddonUrl = this.router.urlFor('app.datastore', addon.get('id'));
        return dataAddonUrl;
      }

      if (addon.isRedis) {
        return this.router.urlFor('app.datastore', addon.get('id'));
      }

      const hasAttachments = (0, _utils.isPresent)(attachments);
      const hasAddonWebUrl = (0, _utils.isPresent)(webUrl);
      let attachmentsUrl;

      if (hasAttachments && hasAddonWebUrl) {
        attachmentsUrl = this._generateAttachmentsUrl(app, attachments);
      }

      return attachmentsUrl || webUrl;
    }),
    webUrlTarget: (0, _object.computed)('addon.isHerokuScheduler', 'session.isAssumed', 'addon.isKafka', 'addon.isReddit', function () {
      const addon = this.addon;
      const session = this.session;

      if (session.isAssumed && addon.isHerokuScheduler || addon.isKafka || addon.isRedis) {
        return '_self';
      } else {
        return '_blank';
      }
    }),

    _generateAttachmentsUrl(app, attachments) {
      const regex = /apps\/(.+)\/addons/;
      const appId = app.id;
      const appAttachmentUrlMap = attachments.reduce((acc, attachment) => {
        const attachmentWebUrl = attachment.webUrl; // Sometimes this is null /shrug

        if (attachmentWebUrl) {
          const match = attachmentWebUrl.match(regex);

          if (match) {
            const matchedId = match[1];
            acc[matchedId] = attachmentWebUrl;
          }
        }

        return acc;
      }, {});
      return appAttachmentUrlMap[appId];
    }

  });

  _exports.default = _default;
});
define("dashboard/components/app/datastore/followers", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "@glimmer/tracking", "ember-concurrency", "ember-concurrency-decorators", "rsvp", "dashboard/config/environment"], function (_exports, _component, _object, _service, _tracking, _emberConcurrency, _emberConcurrencyDecorators, _rsvp, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MIN_POLL_ITERATIONS = 2;

  let _class = (_dec = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), _dec2 = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), _dec3 = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), _dec4 = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "createConfirmation", _descriptor2, this);

      _initializerDefineProperty(this, "showCreateFollowerForm", _descriptor3, this);

      _initializerDefineProperty(this, "newFollowers", _descriptor4, this);

      this.reloadFollowers.perform();
      this.reloadPlans.perform();
    }

    get datastoreId() {
      return (0, _object.get)(this.args.datastore, 'id');
    }

    get app() {
      return (0, _object.get)(this.args.datastore, 'addon.app');
    }

    get initialFollowersLoad() {
      return !this.reloadFollowers?.lastSuccessful?.value && this.reloadFollowers.isRunning;
    }

    get followers() {
      return this.store.peekAll('shogun/postgres-datastore');
    }

    get addonServices() {
      return this.store.peekAll('addon-service');
    }

    get addonService() {
      return this.addonServices.filter(as => as.name === 'heroku-postgresql')[0];
    }

    get addonServicePlans() {
      return this.addonService.addonServicePlans;
    }

    get currentAddonServicePlan() {
      return (0, _object.get)(this.args.datastore, 'addon.addonServicePlan');
    }

    get filteredFollowers() {
      return this.followers.filter(follower => {
        return follower.get('parentDatastore.id') === this.datastoreId;
      }).sortBy('createdAt').reverse();
    }

    get expectedFollowers() {
      return this.filteredFollowers.length + this.newFollowers;
    }

    async getFollowerForm() {
      this.showCreateFollowerForm = (0, _rsvp.defer)();
      const plan = await this.showCreateFollowerForm.promise;

      if (plan) {
        return {
          confirmed: true,
          plan,
          follow: this.datastoreId
        };
      } else {
        return {
          confirmed: false
        };
      }
    }

    async onCreateFollower() {
      const form = await this.getFollowerForm();

      if (form.confirmed) {
        this.createFollowerPromise = (0, _object.get)(this, 'createFollower').perform(form.follow, form.plan);
        await this.createFollowerPromise;
      }
    }

    onConfirmCreate(plan) {
      this.showCreateFollowerForm.resolve(plan);
      this.showCreateFollowerForm = null;
    }

    onCancelCreate() {
      this.showCreateFollowerForm.resolve();
      this.showCreateFollowerForm = null;
    }

    reloadAddonServices() {
      return this.store.findRecord('addon-service', 'heroku-postgresql');
    }

    *reloadPlans() {
      yield this.reloadAddonServices();
      const plans = yield this.addonService.fetchServicePlans(this.store);
      return plans;
    }

    *createFollower(follow, addonServicePlan) {
      const follower = yield this.store.createRecord('addon', {
        app: this.app,
        config: {
          follow
        },
        addonService: this.addonService,
        addonServicePlan
      });
      follower.save();
      this.newFollowers++;
      this.reloadFollowers.perform();
      return follower;
    }

    *reloadFollowers() {
      try {
        const followers = yield this.args.datastore.hasMany('followers').reload();
        this.pollFollowersUntilFinished.perform();
        return followers;
      } catch {
        return [];
      }
    }

    *pollFollowersUntilFinished() {
      while (this.hasPendingFollowers) {
        yield (0, _emberConcurrency.timeout)(2000);
        yield this.reloadFollowers.perform();
      }
    }

    get hasPendingFollowers() {
      const followers = this.filteredFollowers;
      const unfinished = followers.filter(follower => {
        return follower.status !== 'Available';
      });
      return unfinished.length > 0 || followers.length < this.expectedFollowers;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "createConfirmation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "showCreateFollowerForm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "newFollowers", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "followers", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "followers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "addonServices", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "addonServices"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCreateFollower", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onCreateFollower"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onConfirmCreate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onConfirmCreate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCancelCreate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onCancelCreate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reloadPlans", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "reloadPlans"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createFollower", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "createFollower"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reloadFollowers", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "reloadFollowers"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pollFollowersUntilFinished", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "pollFollowersUntilFinished"), _class2.prototype)), _class2));

  _exports.default = _class;
});
define("dashboard/components/apps-empty-state", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    notEmpty,
    alias,
    not,
    and,
    or
  } = _object.computed;

  var _default = _component.default.extend({
    tagName: '',
    isTeam: notEmpty('team'),
    isNotATeam: not('isTeam'),
    isEnterpriseTeam: alias('team.isEnterpriseOrg'),
    isNotEnterpriseTeam: not('isEnterpriseTeam'),
    isDirectTeam: and('isTeam', 'isNotEnterpriseTeam'),
    isSpace: notEmpty('space'),
    canCreateApp: or('isNotATeam', 'team.canCreateApp'),
    wellHeadline: (0, _object.computed)('isSpace', 'isDirectTeam', 'isEnterpriseTeam', function () {
      if (this.get('isSpace')) {
        return `There are no apps in this Space`;
      } else if (this.get('isDirectTeam')) {
        return `There are no apps in this team`;
      } else if (this.get('isEnterpriseTeam')) {
        return `There are no apps in this Enterprise team`;
      } else {
        return `You don't have any apps yet`;
      }
    }),
    wellText: (0, _object.computed)('isSpace', 'isTeam', function () {
      if (this.get('isSpace')) {
        return `Every app you create or become a member of in this Space will appear here`;
      } else if (this.get('isTeam')) {
        return `Every app and pipeline you create or become a member of in this team will appear here`;
      } else {
        return `Every app and pipeline you create or collaborate on appears here.`;
      }
    })
  });

  _exports.default = _default;
});
define("dashboard/controllers/app/datastore/durability", ["exports", "@ember/controller", "@ember/service", "ember-concurrency", "ember-concurrency-decorators", "@ember/object", "dashboard/config/environment"], function (_exports, _controller, _service, _emberConcurrency, _emberConcurrencyDecorators, _object, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const MIN_POLL_ITERATIONS = 2;

  let _class = (_dec = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), _dec2 = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), _dec3 = (0, _emberConcurrencyDecorators.task)({
    drop: true
  }), (_class2 = class _class2 extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "location", _descriptor2, this);
    }

    reset() {
      this.pollUntilFinished.cancelAll({
        resetState: true
      });
      this.deleteBackup.cancelAll({
        resetState: true
      });
      this.downloadBackup.cancelAll({
        resetState: true
      });
      this.createManualBackup.cancelAll({
        resetState: true
      });
      this.reloadBackups.cancelAll({
        resetState: true
      });
    }

    get plan() {
      return (0, _object.get)(this.model.datastore, 'addon.plan');
    }

    get following() {
      return (0, _object.get)(this.model.datastore, 'following');
    }

    get isShieldTier() {
      return this.plan.indexOf('shield') > -1;
    }

    get isBasicTier() {
      return this.plan.indexOf('basic') > -1;
    }

    get isMiniTier() {
      return this.plan.indexOf('mini') > -1;
    }

    get showFollowers() {
      return !this.isBasicTier && !this.isMiniTier && !this.following;
    }

    get lastReload() {
      return this.reloadBackups?.lastSuccessful?.value;
    }

    get initialLoad() {
      return !this.lastReload && this.reloadBackups.isRunning;
    }

    get backups() {
      return this.store.peekAll('shogun/postgres-backup');
    }

    get filteredBackups() {
      const datastoreId = this.model.datastore.get('id');
      return this.backups.filter(backup => {
        return !backup.isNew && backup.get('datastore.id') === datastoreId; // && (backup.prefix === 'b' || backup.prefix === 'c');
      }).sortBy('num').reverse();
    }

    *reloadBackups() {
      const backups = yield this.model.datastore.hasMany('backups').reload();
      this.pollUntilFinished.perform();
      return backups;
    }

    *createManualBackup() {
      const backup = this.store.createRecord('shogun/postgres-backup', {
        datastore: this.model.datastore
      });
      yield backup.save();
      this.reloadBackups.perform();
    }

    *downloadBackup(backup) {
      const url = yield backup.generateDownloadUrl();
      this.location.assign(url);
    }

    *deleteBackup(backup) {
      yield backup.destroyRecord();
      yield this.reloadBackups.perform();
    }

    *pollUntilFinished() {
      // Poll a couple times to make sure `hasUnfinishedBackups` flag
      // has valid data
      let i = MIN_POLL_ITERATIONS;

      while (this.hasUnfinishedBackups || i-- > 0) {
        if (typeof _environment.default.pgBackupPollingInterval === 'number') {
          // wait first so that reloadBackups does not trigger twice immediatly.
          yield (0, _emberConcurrency.timeout)(_environment.default.pgBackupPollingInterval);
          yield this.reloadBackups.perform();
        } else {
          return;
        }
      }
    }

    get hasUnfinishedBackups() {
      const backups = this.get('backups');
      const unfinished = backups.filter(backup => {
        // processed bytes don't initially show once the backup is considered complete
        // so this will keep polling until all info is available
        return (!backup.finishedAt || !backup.processedBytes) && !backup.succeeded === false;
      });
      return unfinished.length > 0;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "location", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "backups", [_object.computed], Object.getOwnPropertyDescriptor(_class2.prototype, "backups"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "reloadBackups", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "reloadBackups"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "createManualBackup", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "createManualBackup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "downloadBackup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "downloadBackup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "deleteBackup", [_emberConcurrencyDecorators.task], Object.getOwnPropertyDescriptor(_class2.prototype, "deleteBackup"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pollUntilFinished", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "pollUntilFinished"), _class2.prototype)), _class2));

  _exports.default = _class;
});
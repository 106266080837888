define("dashboard/templates/components/account/non-app-authorizations-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "QHPjSCq5",
    "block": "[[[41,[30,0,[\"nonAppAuthorizations\"]],[[[1,\"  \"],[10,0],[14,0,\"group-header\"],[12],[1,\"\\n    \"],[10,\"h5\"],[12],[1,\"Registered Authorizations\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"form\"],[12],[1,\"\\n    \"],[10,\"table\"],[14,0,\"table editable-list purple-list\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"nonAppAuthorizations\"]]],null]],null],null,[[[1,\"          \"],[1,[28,[35,3],null,[[\"authorization\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[8,[39,4],null,[[\"@route\"],[\"account.applications.new-authorization\"]],[[\"default\"],[[[[1,\"\\n     \"],[10,\"button\"],[14,0,\"hk-button--primary\"],[12],[1,\"Create authorization\"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\\n\"]],[]],[[[6,[39,5],null,[[\"class\"],[\"mb5\"]],[[\"default\"],[[[[6,[30,2,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"      There are no authorizations yet\\n\"]],[]]]]],[6,[30,2,[\"body\"]],null,[[\"class\"],[\"tc\"]],[[\"default\"],[[[[1,\"      \"],[10,0],[12],[1,\"Authorizations that you create to interact with the Heroku API will appear here.\"],[13],[1,\"\\n      \"],[8,[39,4],null,[[\"@route\"],[\"account.applications.new-authorization\"]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"button\"],[14,0,\"hk-button--primary mt3\"],[12],[1,\"Create authorization\"],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[2]]]]]],[]]]],[\"authorization\",\"well\"],false,[\"if\",\"each\",\"-track-array\",\"account/non-app-authorizations-list-item\",\"link-to\",\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/account/non-app-authorizations-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/controllers/team/switchable/spaces/new", ["exports", "@ember/controller", "@ember/object/computed", "@ember/object"], function (_exports, _controller, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _controller.default.extend({
    title: 'Create Space',
    templateName: 'team/switchable/spaces/new',
    team: (0, _computed.alias)('model.space.team'),
    space: (0, _computed.alias)('model.space'),
    regions: (0, _computed.alias)('model.regions'),
    defaultRegion: (0, _computed.alias)('model.defaultRegion'),
    teamPermissions: (0, _computed.readOnly)('team.currentPermissions'),
    canManageSpaces: (0, _computed.readOnly)('teamPermissions.canManageSpaces'),
    isSavable: (0, _computed.or)('_isNotPersisted', 'space.isDirty'),
    canSave: (0, _computed.and)('isSavable', 'canManageSpaces'),
    cannotSave: (0, _computed.not)('canSave'),
    cannotRenameApp: (0, _computed.not)('canManageSpaces'),
    _isNotPersisted: (0, _computed.not)('space.isPersisted'),
    _cannotCreate: (0, _computed.not)('canManageSpaces'),
    isShowingAdminOnlyCreateWarning: (0, _computed.and)('_isNotPersisted', '_cannotCreate'),
    spendAlertLanguage: (0, _object.computed)('space.shield', function () {
      const hourlyAmount = this.space.shield ? '~$4.167/hour' : '~$1.389/hour';
      const monthlyPrice = this.space.shield ? '$3000/month' : '$1000/month';
      const shieldStatus = this.space.shield ? 'Shield' : '';
      return `<strong>Notice.</strong> By clicking "Create Private Space" below, 
    I agree that during the limited GA period, Heroku will charge my account (${hourlyAmount} / ${monthlyPrice}) 
    for my use of each ${shieldStatus} Private Space ordered above,
    and that after that limited GA period has ended,
    Heroku will charge my account the applicable price set forth in the 
    <a href='https://elements.heroku.com/addons/heroku-private-spaces' target='_blank' rel='noopener noreferrer'>Documentation</a>
    for any continued use of ${shieldStatus} Private Spaces.`;
    }),
    actions: {
      save() {
        this.space.save().then(() => {
          this.transitionToRoute('team.switchable.spaces', this.get('team.name'));
        });
      },

      toggleShield() {
        this.toggleProperty('space.shield');
      }

    }
  });

  _exports.default = _default;
});
define("dashboard/components/crumb-trail", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "@ember/object"], function (_exports, _component, _computed, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['crumb-trail', 'flex', 'items-center', 'flex-auto', 'f3', 'lh-copy', 'near-black', 'nudge-left--5', 'pv--3'],
    tagName: 'nav',
    breadcrumbs: (0, _service.inject)(),
    current: (0, _service.inject)(),
    accountFeatures: (0, _service.inject)(),
    trail: (0, _computed.readOnly)('breadcrumbs.trail'),
    location: (0, _computed.readOnly)('trail.location'),
    space: (0, _computed.readOnly)('trail.space'),
    app: (0, _computed.readOnly)('trail.app'),
    datastore: (0, _computed.readOnly)('trail.datastore'),
    addon: (0, _computed.readOnly)('trail.addon'),
    appIsInPipeline: (0, _computed.readOnly)('app.isInPipeline'),
    appPipeline: (0, _computed.readOnly)('app.pipelineCoupling.pipeline'),
    appPipelineApps: (0, _computed.readOnly)('appPipeline.pipelineCouplings'),
    pipeline: (0, _object.computed)('appIsInPipeline', 'appPipeline', 'trail.pipeline', function () {
      if (this.appIsInPipeline) {
        return this.appPipeline;
      }

      return this.get('trail.pipeline');
    }),
    pipelineHasMultipleApps: (0, _computed.gt)('appPipelineApps.length', 1),
    team: (0, _object.computed)('app', 'app.team.name', 'pipeline', 'pipeline.team.name', 'trail.team', function () {
      if (this.pipeline && this.get('pipeline.team.name')) {
        return this.get('pipeline.team');
      }

      if (this.app && this.get('app.team.name')) {
        return this.get('app.team');
      }

      return this.get('trail.team');
    }),
    enterpriseAccount: (0, _object.computed)('team', '_isTeamMember', '_isTeamEnterpriseAccountMember', 'team.enterprise', 'trail.enterpriseAccount', function () {
      if (this.team && (this._isTeamMember || this._isTeamEnterpriseAccountMember)) {
        return this.get('team.enterprise');
      }

      return this.get('trail.enterpriseAccount');
    }),
    // Some locations behave differently - "Create New App/Team/Pipeline"
    newResourceLocation: (0, _object.computed)('location', function () {
      if (this.get('location')) {
        return this.get('location').match(/new/i);
      }
    }),
    _isTeamMember: (0, _object.computed)('team.role', function () {
      return this.team && this.get('team.role') !== 'collaborator';
    }),
    _isTeamEnterpriseAccountMember: (0, _computed.bool)('team.enterprise.content')
  });

  _exports.default = _default;
});
define("dashboard/components/pipeline/stale-days-control", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    classNames: ['flex'],
    options: [1, 2, 5, 14, 30],
    defaultSelection: (0, _object.computed)('selection', function () {
      return this.selection || 5;
    }),
    actions: {
      changeSelection(option) {
        this.set('selection', option);

        if (this.selectionChanged) {
          this.get('selectionChanged')();
        }
      }

    }
  });

  _exports.default = _default;
});
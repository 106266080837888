define("dashboard/components/datastore/redis/overview/component", ["exports", "@ember/object", "@ember/service", "@glimmer/component", "dashboard/config/environment", "dashboard/utils/metrics/datastore-types", "dashboard/utils/metrics/unit-converters", "dashboard/utils/metrics/calculate-deltas", "ember-concurrency", "ember-concurrency-decorators"], function (_exports, _object, _service, _component, _environment, _datastoreTypes, converter, _calculateDeltas, _emberConcurrency, _emberConcurrencyDecorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const UTILIZATION_KEYS = ['used', 'total', 'connected'];
  const keyRegex = new RegExp('(cmd_)*(?<category>[^_]+)_(?<name>.+)_max');
  const datastoreMetricsPollDuration = _environment.default.datastoreMetricsPollDuration;
  let DatastoreRedisOverview = (_dec = (0, _emberConcurrencyDecorators.task)({
    restartable: true
  }), (_class = class DatastoreRedisOverview extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "browserTime", _descriptor, this);

      _initializerDefineProperty(this, "store", _descriptor2, this);

      _initializerDefineProperty(this, "analytics", _descriptor3, this);

      this.pollMetrics.perform();
    }

    *pollMetrics() {
      while (true) {
        yield this.args.fetchDatastoreMetricsTask.perform(_datastoreTypes.default.redis);

        if (!datastoreMetricsPollDuration) {
          return;
        }

        yield (0, _emberConcurrency.timeout)(datastoreMetricsPollDuration);
      }
    }

    get charts() {
      if (this.args.fetchDatastoreMetricsTask.isRunning) {
        return null;
      }

      const metrics = this.args.datastoreMetrics && this.args.datastoreMetrics.get('rawData');

      if (!metrics) {
        return null;
      }

      return Object.entries(metrics).reduce(groupChart, {});
    }

    get category() {
      return this.args.topic ?? 'utilization';
    }

    get datastoreHealth() {
      if (this.args.datastore.healthy) {
        return {
          status: 'green',
          icon: 'success-badge-16',
          class: 'malibu-fill-gradient-green'
        };
      } else {
        return {
          status: 'red',
          icon: 'error-badge-16',
          class: 'malibu-fill-gradient-red'
        };
      }
    }

    get dropdownItems() {
      if (!this.charts) {
        return [];
      }

      const dropdownItems = Object.keys(this.charts).reject(function (item) {
        return UTILIZATION_KEYS.includes(item);
      });
      return ['utilization', ...dropdownItems];
    }

    get selectedMetricData() {
      if (!this.charts) {
        return [];
      }

      if (this.category === 'utilization') {
        return UTILIZATION_KEYS.map(item => this.charts[item].firstObject);
      } else {
        return this.charts[this.category].reject(chart => chart.name === 'time spent per call');
      }
    }

    updateTimeframe(beforeHours, afterHours) {
      this.analytics.logEvent('Redis Chart Resolution', 'Changed', {
        beforeHours,
        afterHours
      });
      this.args.updateTimeframe(beforeHours, afterHours, _datastoreTypes.default.redis);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "browserTime", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "analytics", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "pollMetrics", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "pollMetrics"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTimeframe", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateTimeframe"), _class.prototype)), _class));
  _exports.default = DatastoreRedisOverview;

  function getScale(name, data) {
    switch (name) {
      case 'calls':
        return converter.scalingInteger(data);

      case 'time spent':
        return converter.scalingMicroSeconds(data);

      case 'time spent per call':
        return converter.scalingMicroSeconds(data);

      case 'memory':
        return converter.scalingBytes(data);

      case 'keys':
        return converter.scalingInteger(data);

      case 'clients':
        return converter.scalingInteger(data);
    }
  }

  function getValueFormat(data) {
    let maxNumDecimalPlaces = 0;
    let maxSigFigPosition = 0;
    data.forEach(d => {
      const value = d[1];
      const newMax = Math.ceil(-Math.log10(value));

      if (newMax < Infinity) {
        maxSigFigPosition = Math.max(newMax, maxSigFigPosition);
      }

      if (value) {
        const decimalPlaces = value.toString().split('.')[1];

        if (decimalPlaces) {
          const numDecimalPlaces = decimalPlaces.length;
          maxNumDecimalPlaces = Math.max(numDecimalPlaces, maxNumDecimalPlaces);
        }
      }
    });
    let valueFormat;

    if (maxSigFigPosition > 0) {
      valueFormat = `0.${'0'.repeat(maxSigFigPosition)}`;
    } else {
      valueFormat = '0,0.';
    }

    if (maxNumDecimalPlaces > maxSigFigPosition) {
      valueFormat += '00';
    }

    return valueFormat;
  }

  function groupChart(acc, _ref) {
    let [rawKey, data] = _ref;
    const matches = keyRegex.exec(rawKey).groups;
    const name = matches.name.replace(/_/g, ' ');
    const category = matches.category;
    const chart = {
      name,
      data,
      unit: null
    }; // These two charts contain aggregate data.
    // Desired behavior is to display this data in deltas.

    if (chart.name === 'calls' || chart.name === 'time spent') {
      data = (0, _calculateDeltas.default)(data);
    }

    const scale = getScale(name, data) || {
      conversion: 1
    };
    chart.data = data.map(_ref2 => {
      let [time, dataum] = _ref2;

      if (dataum === null) {
        return [time, dataum];
      } else {
        return [time, dataum / scale.conversion];
      }
    });
    chart.valueFormat = getValueFormat(chart.data);
    chart.unit = scale.key;
    acc[category] ||= [];
    acc[category].push(chart);
    return acc;
  }
});
define("dashboard/components/app/datastore/create-follower-modal", ["exports", "@glimmer/component", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class2, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let _class = (_class2 = class _class2 extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selectedPlanName", _descriptor, this);

      _initializerDefineProperty(this, "showDownsizingWarning", _descriptor2, this);
    }

    addonServicePlansForTier(tier) {
      return this.args.plans.filter(plan => {
        // split heroku-postgresql:hobby-basic to get just hobby-basic
        const name = this.getPlanTier(plan.name);
        return (name || '').startsWith(tier);
      }).map(plan => {
        const priceInDollars = (plan.price.cents / 100).toFixed(2);
        return {
          label: `${plan.human_name} ($${priceInDollars}/mo)`,
          plan
        };
      });
    }

    getPlanTier(planName) {
      return planName.split(':')[1];
    } // split heroku-postgresql:standard-1 to get just 1


    getPlanLevel(planName) {
      planName = planName.split(':')[1];

      if (!planName) {
        return;
      }

      const planNumber = planName.split('-')[1];

      if (!planNumber) {
        return;
      }

      return Number.parseInt(planNumber, 10);
    }

    get currentPlanName() {
      return (0, _object.get)(this.args.currentPlan, 'name');
    }

    get currentPlanLevel() {
      return this.getPlanLevel(this.currentPlanName);
    }

    get selectedPlanLevel() {
      return this.getPlanLevel(this.selectedPlanName);
    }

    get addonServicePlansGrouped() {
      const availablePlans = [];
      const currentTier = this.getPlanTier(this.currentPlanName);

      if (currentTier.startsWith('standard') || currentTier.startsWith('premium')) {
        availablePlans.push({
          label: 'Standard Tier',
          options: this.addonServicePlansForTier('standard')
        });
      }

      if (currentTier.startsWith('premium')) {
        availablePlans.push({
          label: 'Premium Tier',
          options: this.addonServicePlansForTier('premium')
        });
      }

      if (currentTier.startsWith('private')) {
        availablePlans.push({
          label: 'Private Tier',
          options: this.addonServicePlansForTier('private')
        });
      }

      if (currentTier.startsWith('shield')) {
        availablePlans.push({
          label: 'Shield Tier',
          options: this.addonServicePlansForTier('shield')
        });
      }

      return availablePlans;
    }

    onSelectPlan(planName) {
      this.showDownsizingWarning = false;
      const newPlanLevel = this.getPlanLevel(planName);

      if (this.currentPlanLevel > newPlanLevel) {
        this.showDownsizingWarning = true;
      }

      this.selectedPlanName = planName;
    }

    onConfirm() {
      const selectedPlan = this.args.plans.filter(plan => plan.name === this.selectedPlanName)[0];

      if (selectedPlan) {
        this.args.onConfirm(selectedPlan);
      }
    }

    onCancel() {
      this.args.onCancel();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "selectedPlanName", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.currentPlanName;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "showDownsizingWarning", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "onSelectPlan", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onSelectPlan"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onConfirm", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onConfirm"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onCancel", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onCancel"), _class2.prototype)), _class2);

  _exports.default = _class;
});
define("dashboard/templates/team/switchable/datastores", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "jfm6J6pP",
    "block": "[[[10,\"h1\"],[14,0,\"sr-only\"],[12],[1,\"Datastores\"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"length\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\"]],[]],[[[6,[39,2],null,[[\"class\"],[\"mv4 limit-width w-100\"]],[[\"default\"],[[[[6,[30,2,[\"headline\"]],null,null,[[\"default\"],[[[[1,\"      There are no Heroku managed datastores for this team\\n\"]],[]]]]],[6,[30,2,[\"body\"]],null,null,[[\"default\"],[[[[1,\"      \"],[10,2],[12],[1,\"\\n        Heroku managed datastores are Heroku Redis, Heroku Postgresql and Heroku Kafka.\"],[10,\"br\"],[12],[13],[1,\"\\n        You can add Heroku managed datastores to any app that this team owns and they will show here.\\n        \"],[8,[39,3],[[24,6,\"https://devcenter.heroku.com/articles/managing-add-ons\"]],[[\"@withPrelude\"],[false]],[[\"default\"],[[[[1,\"\\n          Learn more\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]]]],[2]]]]]],[]]]],[\"@model\",\"well\"],false,[\"if\",\"team/datastores-table\",\"hk-well\",\"dev-center-link\"]]",
    "moduleName": "dashboard/templates/team/switchable/datastores.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
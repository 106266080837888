define("dashboard/templates/components/apps-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "IYzCDRsB",
    "block": "[[[18,2,null],[1,\"\\n\"],[6,[39,1],null,[[\"buttonContent\",\"menuClass\",\"hasCaret\",\"buttonClass\"],[[30,0,[\"buttonContent\"]],[30,0,[\"dropdownClass\"]],true,\"link fw4 purple bn pa0 bg-transparent nowrap f5\"]],[[\"default\"],[[[[42,[28,[37,3],[[28,[37,3],[[30,0,[\"apps\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,4],[[24,\"classNames\",\"text-left\"]],[[\"@route\",\"@model\"],[[30,0,[\"linkToRoute\"]],[30,1]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,5],null,[[\"@title\",\"@name\",\"@iconClass\",\"@size\"],[\"App\",\"app-16\",\"malibu-fill-gradient-purple\",16]],null],[1,\"\\n        \"],[1,[30,1]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null]],[]]]]]],[\"app\",\"&default\"],false,[\"yield\",\"drop-down\",\"each\",\"-track-array\",\"link-to\",\"malibu-icon\"]]",
    "moduleName": "dashboard/templates/components/apps-list.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
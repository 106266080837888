define("dashboard/components/enterprise-account/welcome-banner-feature", ["exports", "@ember/component", "@ember/object/computed", "@ember/service"], function (_exports, _component, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    tagName: '',
    router: (0, _service.inject)(),
    analytics: (0, _service.inject)(),
    // --------------------------------------------------------------------------
    // Arguments
    feature: null,
    // --------------------------------------------------------------------------
    // Computed Properties
    icon: (0, _computed.readOnly)('feature.icon'),
    title: (0, _computed.readOnly)('feature.title'),
    description: (0, _computed.readOnly)('feature.description'),
    linkTitle: (0, _computed.readOnly)('feature.linkTitle'),
    linkUrl: (0, _computed.readOnly)('feature.linkUrl'),
    analyticsNoun: (0, _computed.readOnly)('feature.analyticsNoun'),
    linkQuery: (0, _computed.readOnly)('features.linkQuery')
  });

  _exports.default = _default;
});
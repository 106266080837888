define("dashboard/templates/components/dyno-tier-picker-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "zPTvJfyZ",
    "block": "[[[10,0],[14,0,\"dyno-tier-picker-header\"],[12],[1,\"\\n  Professional\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"dyno-tier-picker-header-placeholder\"],[12],[13],[1,\"\\n\\n\"],[10,0],[15,0,[29,[\"dyno-tier-picker-item-sizes \",[52,[30,0,[\"isEcoWithoutSubscription\"]],\"mb2\"]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"tier\",\"cells\"]]],null]],null],null,[[[1,\"    \"],[1,[28,[35,3],null,[[\"size\",\"isEcoWithoutSubscription\"],[[30,1],[30,0,[\"isEcoWithoutSubscription\"]]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"dyno-tier-picker-item-select-btn\"],[12],[1,\"\\n\"],[41,[30,0,[\"isEcoWithoutSubscription\"]],[[[1,\"    \"],[10,0],[14,0,\"flex flex-column dyno-tier-picker-item-select__link items-center dyno-tier-price-label mb1\"],[12],[1,\"\\n      \"],[11,0],[24,0,\"hk-button-sm--primary\"],[24,\"role\",\"button\"],[4,[38,4],[[30,0],\"transitionToAccountBilling\"],null],[12],[1,[30,0,[\"displayPrices\",\"hourPrice\"]]],[13],[1,\"\\n      \"],[10,0],[12],[1,[30,0,[\"displayPrices\",\"monthPrice\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"dyno-tier-price-label dyno-tier-picker-item-select__link flex flex-column\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"f5\"],[12],[1,[30,0,[\"displayPrices\",\"hourPrice\"]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"f6 fw4\"],[12],[1,\"\\n\"],[41,[30,0,[\"tier\",\"isEco\"]],[[[1,\"          Flat fee of \"],[1,[30,0,[\"displayPrices\",\"monthPrice\"]]],[1,\" \\n\"]],[]],[[[1,\"          Max of \"],[1,[30,0,[\"displayPrices\",\"monthPrice\"]]],[1,\"\\n\"]],[]]],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[\"cell\"],false,[\"if\",\"each\",\"-track-array\",\"dyno-tier-picker-item-size\",\"action\"]]",
    "moduleName": "dashboard/templates/components/dyno-tier-picker-item.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
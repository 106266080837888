define("dashboard/templates/components/app/overview-metrics", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "34+S0qo9",
    "block": "[[[10,0],[14,0,\"flex-ns items-center pv2\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"flex dark-gray mr2\"],[12],[1,\"Metrics (last 24hrs)\"],[13],[1,\"\\n  \"],[10,0],[14,0,\"flex-auto\"],[12],[13],[1,\"\\n  \"],[8,[39,0],[[24,\"classNames\",\"flex items-center hk-link no-underline f6 mv1 mv0-ns\"]],[[\"@route\"],[\"app.metrics\"]],[[\"default\"],[[[[1,\"\\n    \"],[10,1],[14,0,\"underline\"],[12],[1,\"All Metrics\"],[13],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"nav-go-16\",\"fill-blue ml--3 nudge-down--1\",16]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[6,[39,2],null,[[\"app\",\"isVisible\",\"hasVerboseErrors\",\"overviewPage\",\"alerts\"],[[30,0,[\"app\"]],true,true,true,[30,0,[\"alerts\"]]]],[[\"default\"],[[[[1,\"  \"],[1,[28,[35,3],null,[[\"class\",\"headline\"],[\"mb4 w-100 h6 items-center justify-center flex\",\"No Metrics data in the last 24 hours\"]]]],[1,\"\\n\"]],[]]]]]],[],false,[\"link-to\",\"malibu-icon\",\"app-item-favorite-tray/metrics\",\"hk-well\"]]",
    "moduleName": "dashboard/templates/components/app/overview-metrics.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/templates/components/app/datastore/credential-attachment", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "8Hn0ESh1",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"pv1\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"dib nowrap overflow-hidden mw-20rem overflow-ellipsis\"],[12],[1,\"\\n      \"],[10,\"code\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\" on \"],[1,[30,1,[\"app\",\"name\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,\"td\"],[14,0,\"pv1 pl2 nowrap\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@default\",\"@buttonType\",\"@onClick\",\"@disableWhen\"],[\"Detach\",\"danger\",[28,[37,1],[[30,2],[30,1]],null],[30,0,[\"isDetachingDisabled\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@credentialAttachment\",\"@detachCredential\"],false,[\"hk-button\",\"fn\"]]",
    "moduleName": "dashboard/templates/components/app/datastore/credential-attachment.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/serializers/shogun/postgres-datastore", ["exports", "dashboard/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /* eslint-disable complexity */
  var _default = _application.default.extend({
    normalize(_typeClass, hash) {
      const addon = this.store.peekRecord('addon', hash.addon_id);
      const appId = addon && addon.get('app.id');
      hash.links = {
        config: `/postgres/v0/databases/${hash.addon_id}/config`,
        credentials: `/postgres/v0/databases/${hash.addon_id}/credentials`,
        backups: `/client/v11/apps/${appId}/transfers`,
        followers: `/client/v11/databases/${hash.addon_id}/followers`
      };
      hash.addon = hash.addon_id;
      hash.role = hash.following ? 'follower' : 'leader';
      const connectionsItem = hash.info.find(i => i.name === 'Connections');

      if (connectionsItem) {
        hash.connections = connectionsItem.values[0];
      }

      const dataSizeItem = hash.info.find(i => i.name === 'Data Size');

      if (dataSizeItem) {
        hash.data_size = dataSizeItem.values[0];
      }

      const maintenanceItem = hash.info.find(i => i.name === 'Maintenance');
      hash.maintenance = maintenanceItem ? maintenanceItem.values[0] : 'Unsupported';
      const pgVersionItem = hash.info.find(i => i.name === 'PG Version');

      if (pgVersionItem) {
        hash.pg_version = pgVersionItem.values[0];
      }

      const planItem = hash.info.find(i => i.name === 'Plan');

      if (planItem) {
        hash.plan = planItem.values[0];
      }

      const regionItem = hash.info.find(i => i.name === 'Region');

      if (regionItem) {
        hash.region = regionItem.values[0];
      }

      const rollbackItem = hash.info.find(i => i.name === 'Rollback');

      if (rollbackItem) {
        hash.rollback = rollbackItem.values[0];
      }

      const rowsItem = hash.info.find(i => i.name === 'Rows');

      if (rowsItem) {
        hash.rows = rowsItem.values[0];
      }

      const statusItem = hash.info.find(i => i.name === 'Status');

      if (statusItem) {
        hash.status = statusItem.values[0];
      }

      const tablesItem = hash.info.find(i => i.name === 'Tables');

      if (tablesItem) {
        hash.tables = tablesItem.values[0];
      }

      return this._super.apply(this, arguments);
    },

    extractId(_modelClass, resourceHash) {
      return resourceHash.addon_id;
    }

  });

  _exports.default = _default;
});
define("dashboard/templates/app/release", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "FCbLx51E",
    "block": "[[[10,0],[14,0,\"build-view limit-width\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"page-breadcrumb\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@model\"],[\"app.activity\",[30,0,[\"model\",\"app\",\"name\"]]]],[[\"default\"],[[[[1,\"\\n      Activity Feed\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,1],[[24,\"aria-hidden\",\"true\"],[24,\"aria-hidden\",\"true\"]],[[\"@name\",\"@iconClass\",\"@size\"],[\"nav-forward-16\",\"fill-gray ml1 mb1 mr1\",16]],null],[1,\"\\n    Release Log\\n\\n    \"],[10,0],[14,0,\"build-id pull-right\"],[12],[1,\"\\n      ID \"],[1,[30,0,[\"model\",\"release\",\"id\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"release\",\"outputStreamUrl\"]],[[[1,\"    \"],[1,[28,[35,3],null,[[\"streamUrl\",\"size\",\"buildFinishedLabel\"],[[30,0,[\"model\",\"release\",\"outputStreamUrl\"]],\"full\",\"Release finished\"]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,4],null,[[\"headline\",\"body\"],[\"No output stream available\",\"This release does not exist or does not have a build output stream.\"]]]],[1,\"\\n    \"],[10,\"h5\"],[12],[13],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"malibu-icon\",\"if\",\"build-stream\",\"hk-well\"]]",
    "moduleName": "dashboard/templates/app/release.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/templates/components/metrics/chart-controls/timeframe-selector", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "3GL+qGUU",
    "block": "[[[6,[39,0],null,[[\"buttonComponent\",\"menuClass\",\"buttonClass\"],[[50,\"metrics/chart-controls/timeframe-selector/button-content\",0,null,[[\"label\"],[[30,0,[\"selectedTimeframe\",\"label\"]]]]],\"dropdown-menu metrics__time-picker__menu\",\"btn btn-default metrics__time-picker__btn\"]],[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"timeframeOptions\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,4],null,[[\"value\",\"app\",\"selectedTimeframe\",\"premiumOptionsDisabled\",\"onSelect\"],[[30,1],[30,0,[\"app\"]],[30,0,[\"selectedTimeframe\"]],[30,0,[\"premiumOptionsDisabled\"]],[28,[37,5],[[30,0],\"selected\",[30,1]],null]]]]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"premiumOptionsDisabled\"]],[[[1,\"      \"],[10,\"li\"],[14,0,\"metrics__time-picker__note\"],[12],[1,\"\\n        \"],[8,[39,7],[[24,0,\"link\"]],[[\"@route\"],[\"app.resources\"]],[[\"default\"],[[[[1,\"\\n          Upgrade to Professional Dynos\\n        \"]],[]]]]],[1,\" to access all timeframes.\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[\"option\"],false,[\"drop-down\",\"component\",\"each\",\"-track-array\",\"metrics/chart-controls/timeframe-option\",\"action\",\"if\",\"link-to\"]]",
    "moduleName": "dashboard/templates/components/metrics/chart-controls/timeframe-selector.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});
define("dashboard/templates/components/app/datastore/follower-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "RDcg2Zc2",
    "block": "[[[10,\"tr\"],[14,0,\"dark-gray hover-bg-lightest-silver f5 jray\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"pa3\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@models\"],[\"app.datastore\",[28,[37,1],[[30,1,[\"datastore\",\"addon\",\"appName\"]],[30,1,[\"id\"]]],null]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,1,[\"name\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"pa3\"],[12],[1,\"\\n\"],[41,[30,0,[\"isPending\"]],[[[1,\"      \"],[1,[34,3]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[30,1,[\"pgVersion\"]]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"pa3\"],[12],[1,\"\\n\"],[41,[30,1,[\"createdAt\"]],[[[1,\"      \"],[1,[28,[35,4],null,[[\"datetime\"],[[28,[37,5],[[30,1,[\"createdAt\"]]],null]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[14,0,\"pa3\"],[12],[1,\"\\n\"],[41,[30,1,[\"status\"]],[[[1,\"      \"],[1,[30,1,[\"status\"]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@follower\"],false,[\"link-to\",\"array\",\"if\",\"loading-spinner\",\"time-ago\",\"iso-date\"]]",
    "moduleName": "dashboard/templates/components/app/datastore/follower-row.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});